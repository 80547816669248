<template>
  <div class="admin-new-order-form">
    <div class="stock-change-card">
      <h6>Summary</h6>
      <div class="stock-change-card-products">
        <div v-for="(item, index) in cart" :key="index" class="stock-change-card-product">
          <div class="stock-change-card-product-info">
            <img v-if="item.fileUrl" :src="`${item.fileUrl}`" alt="" />
            <span>{{ item.name }}</span>
          </div>
          <table-number-input v-model="item.stock_update" :value="item.stock_update" @input="cartChange" />
          <div class="stock-change-card-product-price">
            {{ item.priceWithTax | price }}
          </div>
          <img class="stock-change-card-product-delete" src="@/assets/images/icons/close.svg"
            @click="deleteFromCart(index)" alt />
        </div>
      </div>
      <div v-if="(cart && cart.length)" class="stock-change-card-discount">
        <span>Discount</span>
        <b-form-input type="number" v-model="orderObject.discount" />
      </div>
      <div v-if="(cart && cart.length)" class="stock-change-card-discount mt-0">
        <span>Shipping Fee</span>
        <span class="sum">{{ shippingFee | price }}</span>
      </div>
      <div v-if="(cart && cart.length && depositSum)" class="stock-change-card-discount mt-0">
        <span>Deposit Fee</span>
        <span class="sum">{{ depositSum | price }}</span>
      </div>
      <div class="stock-change-card-total">
        <span>Total</span>
        <strong class="sum">{{ cartTotal | price }}</strong>
      </div>
    </div>

    <b-alert v-if="orderObject.noticeWP" show variant="warning">
      {{ orderObject.noticeWP }}
    </b-alert>

    <validation-observer ref="orderForm" tag="form" @submit.prevent="onSubmit">
      <b-form>
        <div v-if="enableStatus" class="row">
          <div class="col-12">
            <div class="main-dropdown">
              <b-form-group label="Order status" label-for="status">
                <validation-provider #default="{ errors }" name="Order status" rules="required" vid="status">
                  <v-select id="status" class="custom-select" v-model="status" :options="statuses" :clearable="false"
                    :searchable="false" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="main-dropdown">
              <b-form-group label="Payment method" label-for="payment">
                <validation-provider #default="{ errors }" name="Payment" rules="required" vid="payment">
                  <v-select id="payment" class="custom-select" v-model="orderObject.payment" :options="paymentMethodList"
                    :clearable="false" :searchable="false" :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <b-form-group label="Postcode" label-for="postcode">
              <validation-provider #default="{ errors }" rules="required" name="postcode" vid="orderObject.postalCode">
                <b-form-input v-model="orderObject.postalCode" id="postcode" @input="postalCodeChange"
                  :state="errors.length > 0 ? false : null" />
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-6">
            <div class="main-dropdown">
              <b-form-group label="Street" label-for="street">
                <validation-provider #default="{ errors }" rules="required" name="street" vid="street">
                  <v-select id="street" class="custom-select" v-model="selectedAddress" :options="streets"
                    :clearable="false" :searchable="true" taggable :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="col-3">
            <b-form-group label="Number" label-for="number">
              <validation-provider #default="{ errors }" rules="required" name="number" vid="orderObject.streetNumber">
                <b-form-input v-model="orderObject.streetNumber" id="number" :state="errors.length > 0 ? false : null" />
              </validation-provider>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <b-form-group label="Entrance" label-for="entrance">
              <b-form-input v-model="orderObject.gate" id="entrance" />
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group label="Flat number" label-for="flatNumber">
              <validation-provider #default="{ errors }" name="flatNumber" vid="orderObject.flatNumber">
                <b-form-input v-model="orderObject.flatNumber" id="flatNumber"
                  :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="col-4">
            <b-form-group label="Floor" label-for="floor">
              <b-form-input v-model="orderObject.floorNumber" id="floor" />
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="main-dropdown">
              <b-form-group label="Phone" label-for="selectedPhone">
                <validation-provider
                  rules="required"
                  #default="{ errors }"
                  name="phone"
                  vid="selectedPhone"
                >
                    <custom-select-with-search
                        id="selectedPhone"
                        v-model="selectedPhone"
                        :options="phoneList"
                        resource="$Users"
                        resource-type="client_telephone_list"
                        clearable
                        taggable
                        lazyLoad
                        @input="phoneSelected($event)"
                        :state="errors.length > 0 ? false : null"
                    />
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div class="col-6">
            <b-form-group label="Email" label-for="email">
              <validation-provider #default="{ errors }" name="email" vid="orderObject.email">
                <b-form-input v-model="orderObject.email" id="email" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <b-form-group label="Comment" label-for="comment">
              <validation-provider #default="{ errors }" name="comment" vid="orderObject.description">
                <b-form-input v-model="orderObject.description" id="comment" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <b-form-group label="Delivery time" label-for="date">
              <flat-pickr v-model="orderObject.shippingDate" :config="{
                altFormat: 'd.m.Y H:i',
                dateFormat: 'Z',
                altInput: true,
                altInputClass: 'flatpickr-input',
                time_24hr: true,
                enableTime: true,
              }"></flat-pickr>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <b-form-group label-for="warehouse">
              <b-form-checkbox v-model="orderObject.fromWarehouse" id="warehouse">
                Ship from warehouse
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>
        <b-button @click="onSubmit" variant="success">{{ btnName() }}</b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'flatpickr/dist/flatpickr.css'
import { BButton, BFormGroup, BFormInput, BForm } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import TableNumberInput from '@/components/Inputs/TableNumberInput.vue'
import 'vue-good-table/dist/vue-good-table.css'
import Toast from '@/components/Toast.vue'
import router from '@/router'
import ListUtils from '@/mixins/ListUtils'
import CustomSelectWithSearch from '@/components/Inputs/CustomSelectWithSearch.vue'
import { getUserData } from "@/auth/utils"

export default {
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    TableNumberInput,
    BButton,
    CustomSelectWithSearch,
    ValidationProvider,
    ValidationObserver,
    flatPickr
  },
  props: {
    orderObj: { Object, required: true },
    cart: {
      Array,
      default() {
        return []
      },
    },
    action: { type: String, default: null },
    enableStatus: { type: Boolean, default: false },
  },
  data() {
    return {
      userData: getUserData(),
      apiUrl: this.$store.state.apiUrl,
      orderObject: this.orderObj,
      shippingFee: 0,
      paymentMethodList: [],
      phoneList: this.orderObj.phoneList,
      selectedPhone: this.orderObj.selectedPhone,
      selectedAddress: null,
      streets: [],
      addresses: null,
      statuses: [],
      status: null,
    }
  },
  async created() {
    let existingStreet = false
    this.getEnumList('order_payment_method', this.paymentMethodList)
    this.addresses = await this.getLocationList()
    this.addresses.forEach(address => {
      this.streets.push({
        label: address.streetAddress,
        district: address.postalCode,
        id: address.streetAddress,
      })

      // if edit
      if (
        this.orderObject.streetAddress &&
        this.orderObject.streetAddress === address.streetAddress
      ) {
        existingStreet = true
        this.selectedAddress = {
          label: address.streetAddress,
          district: address.postalCode,
          id: address.streetAddress,
        }
      }
    })

    // if edit and street is not in the street list
    if (!existingStreet) {
      this.selectedAddress = {
        label: this.orderObject.streetAddress,
        district: 0,
        id: this.orderObject.streetAddress,
      }
    }

    if (this.userData.role !== 'ROLE_COURIER') {
      this.shippingFee = 1
    }

    if (this.enableStatus) {
      this.getEnumList('order_status', this.statuses)
    }
    if (this.orderObject.status) {
      this.status = {
        label: this.$helper.getEnumTranslation(
          'order_status',
          this.orderObject.status,
        ),
        id: this.orderObject.status,
      }
    }
  },
  computed: {
    cartTotal() {
      let total = 0
      let depositSum = 0
      this.cart.forEach(product => {
        total += product.priceWithTax * product.stock_update
        if (product.depositPrice) {
          depositSum += product.depositPrice * product.stock_update
        }
      })
      if (this.orderObject.discount) {
        total -= this.orderObject.discount
      }

      total += this.shippingFee

      if (depositSum) {
        total += depositSum
      }
      return total.toFixed(2)
    },
    prodSum() {
      let total = 0
      this.cart.forEach(product => {
        total += product.priceWithTax * product.stock_update
      })
      return total.toFixed(2)
    },
    depositSum() {
      let total = 0
      this.cart.forEach(product => {
        if (product.depositPrice) {
          total += product.depositPrice * product.stock_update
        }
      })
      return total.toFixed(2)
    }
  },
  mixins: [ListUtils],
  methods: {
    getUsers(search, loading) {
      const params = {
        role: ['ROLE_CLIENT'],
      }
      if (loading) {
        loading(true)
      }
      if (search && search.length) {
        params.search = search
        this.phoneList = []
      }
      const headers = {
        fields: '"/hydra:member/*/@id", "/hydra:member/*/telephone"',
      }
      this.$Users.getCollection({ params, headers }).then(response => {
        if (loading) {
          loading(false)
        }
        response.data['hydra:member'].forEach(user => {
          if (user.telephone) {
            this.phoneList.push({
              label: user.telephone,
              id: user['@id'],
            })
          }
        })
      })
    },
    btnName() {
      let btnName = 'Create order'
      if (this.action === 'update') {
        btnName = 'Update order'
      }
      return btnName
    },
    deleteFromCart(index) {
      this.cart.splice(index, 1)
      this.cartChange()
    },
    cartChange() {
      this.orderObject.discount = 0
    },
    onSubmit() {
      this.$refs.orderForm.validate().then(success => {
        if (success) {
          const { id } = router.currentRoute.params
          const body = {
            paymentMethod: this.orderObject.payment.id,
            items: [],
            shippingAddress: {
              streetAddress: (this.selectedAddress.label) ? this.selectedAddress.label : this.selectedAddress,
              streetNumber: this.orderObject.streetNumber,
              postalCode: this.orderObject.postalCode,
              city: this.orderObject.city,
              gate: this.orderObject.gate,
              flatNumber: this.orderObject.flatNumber,
              floorNumber: this.orderObject.floorNumber,
            },
            description: this.orderObject.description,
            discount: this.orderObject.discount || null,
            email: this.orderObject.email,
            telephone: this.selectedPhone.label
              ? this.selectedPhone.label
              : null,
            user: this.orderObject.user ? this.orderObject.user['@id'] : null,
            status: this.status && this.status.id ? this.status.id : null,
            fromWarehouse: this.orderObject.fromWarehouse,
          }

          if (this.orderObject.shippingDate) {
            body.shippingDate = moment(this.orderObject.shippingDate).format()
          }

          if (this.action === 'update') {
            this.cart.forEach(product => {
              body.items.push({
                productVariantId:
                  product['@type'] === 'ProductVariant'
                    ? product.id
                    : product.productVariantId,
                quantity: parseInt(product.stock_update, 10),
              })
            })
            this.$Orders
              .updateResource({ id, body })
              .then(response => {
                if (response.status === 200) {
                  this.$router.push((this.userData && this.userData.role === 'ROLE_DISPATCHER') ? '/dispatcher/orders' : '/')
                  this.$helper.showToast(
                    this.$toast,
                    Toast,
                    'Order updated',
                    '',
                    'success',
                  )
                }
              })
              .catch(error => {
                if (error.response) {
                  this.$helper.showToast(
                    this.$toast,
                    Toast,
                    error.response.data['hydra:title'],
                    error.response.data['hydra:description'],
                    'danger',
                  )
                }
              })
          } else {
            body.status = 'NEW'
            body.createdBy = this.userData["@id"]
            this.cart.forEach(product => {
              body.items.push({
                productVariantId: product.id,
                quantity: parseInt(product.stock_update, 10),
              })
            })
            this.$Orders
              .createResource({ body })
              .then(response => {
                if (response.status === 201) {
                  this.$router.push((this.userData && this.userData.role === 'ROLE_DISPATCHER') ? '/dispatcher/orders' : '/')
                  this.$helper.showToast(
                    this.$toast,
                    Toast,
                    'Order created',
                    '',
                    'success',
                  )
                }
              })
              .catch(error => {
                if (error.response) {
                  this.$helper.showToast(
                    this.$toast,
                    Toast,
                    error.response.data['hydra:title'],
                    error.response.data['hydra:description'],
                    'danger',
                  )
                }
              })
          }
        }
      })
    },
    phoneSelected($event) {
      if ($event && $event.id) {
        this.selectedPhone = $event
      } else {
        this.selectedPhone = { id: null, label: $event }
      }
      this.orderObject.streetAddress = this.orderObject.streetAddress
        ? this.orderObject.streetAddresshmac
        : null
      this.orderObject.streetNumber = this.orderObject.streetNumber
        ? this.orderObject.streetNumber
        : null
      this.orderObject.postalCode = this.orderObject.postalCode
        ? this.orderObject.postalCode
        : null
      this.orderObject.gate = this.orderObject.gate
        ? this.orderObject.gate
        : null
      this.orderObject.flatNumber = this.orderObject.flatNumber
        ? this.orderObject.flatNumber
        : null
      this.orderObject.floorNumber = this.orderObject.floorNumber
        ? this.orderObject.floorNumber
        : null
      this.orderObject.email = this.orderObject.email
        ? this.orderObject.email
        : null
      this.orderObject.user = this.orderObject.user
        ? this.orderObject.user
        : null
      if (this.selectedPhone && this.selectedPhone.id) {
        this.$Users
          .getResourceByUrl({ url: this.selectedPhone.id })
          .then(response => {
            const user = response.data
            this.orderObject.streetAddress = user.address.streetAddress
            this.orderObject.streetNumber = user.address.streetNumber
            this.orderObject.postalCode = user.address.postalCode
            this.orderObject.gate = user.address.gate
            this.orderObject.flatNumber = user.address.flatNumber
            this.orderObject.floorNumber = user.address.floorNumber
            this.orderObject.email = user.email
            this.orderObject.user = user

            this.selectedAddress = this.streets.find(adr => adr.label === this.orderObject.streetAddress)
          })
      }
    },
    postalCodeChange() {
      if (this.orderObject.postalCode) {
        this.streets = []
        this.addresses.forEach(address => {
          if (address.postalCode.includes(this.orderObject.postalCode)) {
            this.streets.push({
              label: address.streetAddress,
              district: address.postalCode,
              id: address.streetAddress,
            })
          }
        })
      } else {
        this.streets = []
        this.addresses.forEach(address => {
          this.streets.push({
            label: address.streetAddress,
            district: address.postalCode,
            id: address.streetAddress,
          })
        })
      }
    },
  },
}
</script>
