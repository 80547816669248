<template>
    <div class="dropdown-container">
        <div class="main-dropdown custom-select">
            <v-select
                v-model="selectedItem"
                :options="selectOptions"
                class="custom-select"
                :clearable="clearable"
                :multiple="multiple"
                :taggable="taggable"
                :searchable="searchable || resource"
                :selectable="(option) => !option.disabled"
                @search="searchOptions"
            >
            </v-select>
            <img class="custom-select-picture" v-if="selectedItem && selectedItem.fileUrl" :src="selectedItem.fileUrl"  alt=""/>
        </div>
    </div>
</template>

<script>

import vSelect from "vue-select"
import ListUtils from "@/mixins/ListUtils"

export default {
    name: 'CustomSelectWithSearch',
    components: {
        vSelect
    },
    mixins: [ListUtils],
    props: {
        name: { type: String },
        value: { type: [Object, String], default: null },
        passedParentObject: { type: Object, default: null},
        clearable: { type: Boolean, default: true},
        options: { type: Array },
        preload: { type: Boolean, default: false},
        lazyLoad: { type: Boolean, default: true},
        multiple: { type: Boolean, default: false},
        taggable: { type: Boolean, default: false},
        searchable: { type: Boolean, default: true},
        resource: { type: String, default: null},
        resourceType: { type: String, default: null},
        entityType: { type: String, default: null},
        enumName: { type: String, default: null},
        categoryName: { type: String, default: null},
        params: { type: Object, default: null},
        requestArgs: [],
    },
    watch: {
        selectedItem(item) {
            if(this.passedParentObject) {
                this.$emit('changeWithParent', { item, parent: this.passedParentObject } )
            } else {
                this.$emit('input', item)
            }
        }
    },
    data() {
        return {
            requestArgsObject: this.requestArgs,
            resourceObject: this.resource,
            selectedItem: this.value,
            selectOptions: this.options,
            selectLazyLoad: this.lazyLoad,
            previousOptions: [],
            first: true,
            placeholder: {
                id: null,
                label: 'Start typing to search',
                disabled: true
            }
        }
    },
    created() {
        if (typeof this.resourceObject === 'string') {
            this.resourceObject = this[this.resourceObject]
        }

        if (this.$helper.hasQueryParameter(this.$route, this.name)) {
            this.selectLazyLoad = false
        }

        this.loadOptions()

        setTimeout(() => {
            this.setValueFromUrl()
        }, 500)

        this.first = false
    },
    methods: {
        setValueFromUrl() {
            if(this.$helper.hasQueryParameter(this.$route, this.name) === false) {
                return
            }

            const query = JSON.parse(JSON.stringify(this.$route.query))
            let value = decodeURIComponent(query[this.name])

            let selectedValue = null

            if (this.multiple) {
                if (value.includes(',')) {
                    value = value.split(',')
                }
                if (Array.isArray(value) === false) {
                    value = [value]
                }
            }

            if (Array.isArray(value)) {
                selectedValue = []
                value.forEach(val => {
                    selectedValue.push(this.$helper.findElementOptionInList(this.selectOptions, val))
                })
            } else {
                selectedValue = this.$helper.findElementOptionInList(this.selectOptions, value)
            }

            this.selectedItem = selectedValue
        },
        loadOptions(search) {
            this.selectOptions = []

            if (this.selectLazyLoad && typeof search === 'undefined') {
                this.selectOptions = [this.placeholder]
                return
            }

            if (typeof search === 'string' && (search.trim() === '' || search.length < 3)) {
                return
            }

            if (!this.resourceObject || !this.resourceType) {
                return
            }

            const defaultParams = { pagination: false }

            if (!this.selectLazyLoad) {
                defaultParams.cache = true
                defaultParams.ttl = 86400
            }

            if (search !== '') {
                defaultParams.search = search
            }

            if (search === '' && this.selectLazyLoad === false && this.previousOptions.length) {
                this.selectOptions = this.previousOptions
                return
            }

            const params = { ...this.params, ...defaultParams }

            if (this.resourceType === 'entity' || this.resourceType === 'station' || this.resourceType === 'importantUpdate' || this.resourceType === 'entityOption') {
                if (this.resourceType === 'entityOption' && this.entityType !== null) {
                    params.type = this.entityType
                }
                this.getResourceList(this.resourceObject, this.selectOptions, params)
            }

            if (this.resourceType === 'enum') {
                this.getEnumList(this.enumName, this.selectOptions)
            }

            if (this.resourceType === 'client_telephone_list') {
                params.role = "ROLE_CLIENT"
                params.pagination = true
                this.getUserPhoneList({ params }, this.selectOptions)
            }

            if (!this.selectLazyLoad && this.first) {
                this.previousOptions = this.selectOptions
            }

            if (this.selectLazyLoad && search === '' && this.selectOptions.length === 0) {
                this.selectOptions.push(this.placeholder)
            }
        },
        searchOptions(search) {
            this.loadOptions(search)
        }
    }
}
</script>
